import React from 'react';
import Helmet from 'react-helmet';
import config from '../../data/SiteConfig';
import styled from 'styled-components';
import './index.css';

export default class Layout extends React.Component {
	render() {
		const { children } = this.props;
		return (
			<Container>
				<Helmet>
					<meta name="description" content={config.siteDescription} />
					<html lang="en" />
				</Helmet>
				{children}
			</Container>
		);
	}
}

const Container = styled.div`
	// padding: 48px;
`;
